import { useTranslation, } from 'react-i18next';
import Grid from '@mui/material/Grid';
import AppContainer from 'Components/AppContainer';
import { SELLER_MENU_ITEMS, } from 'Components/Layouts/LayoutSeller/constants';
import { hasAuthUserPermission, useAuthUser, } from 'Tools/auth';
import { Claim, PrizeWheelTypeEnum, usePrizeWheelQuery, } from 'Apollo/graphql';
import { isCzechMarket, } from 'Utils/market';
import MenuItem from './MenuItem';

const DashboardSellerPage = (): JSX.Element => {
  const { t, } = useTranslation('common');
  const authUser = useAuthUser();

  const { data: prizeWheelXmas2023, } = usePrizeWheelQuery({
    variables: {
      type: PrizeWheelTypeEnum.Xmas_2023,
    },
    fetchPolicy: 'network-only',
    skip: !authUser?.claimsSet.has(Claim.GeneratePrizeWheelXmas_2023),
  });

  return (
    <AppContainer maxWidth="md">
      <Grid container spacing={{ xs: 1, sm: 3.75, }}>
        {SELLER_MENU_ITEMS.filter((item) => {
          // FIXME: this is shitttttt
          if (item.id === 'czGiftCodes') {
            return authUser?.claimsSet.has(Claim.ReadGiftCode) && isCzechMarket;
          }

          if (item.id === 'xmasWheel2023') {
            const hasPrize = prizeWheelXmas2023?.prizeWheel !== null;
            const hasPermission = authUser?.claimsSet.has(Claim.GeneratePrizeWheelXmas_2023);

            if (hasPrize || !hasPermission) {
              return false;
            }
          }

          return true;
        }).map((item) => {
          if (!authUser?.pointOfSell?.createsReplacements && authUser?.pointOfSell !== null && item.id === 'replacements') {
            return <div key={item.id} />;
          }
          return (
            <MenuItem key={item.id} item={item} isAllowed={hasAuthUserPermission(authUser, item.claims, item.flag)} sx={item.sx}>
              {t(item.textKey)}
            </MenuItem>
          );
        })}
      </Grid>
    </AppContainer>
  );
};

export default DashboardSellerPage;
