import { useState, } from 'react';
import shallow from 'zustand/shallow';
import { useTranslation, } from 'react-i18next';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import AppContainer from 'Components/AppContainer';
import {
  RegistrationStore,
  useRegistrationStore,
} from 'Modules/RegistrationsAdmin/store/RegistrationStore';
import {
  PurchasesStore,
  usePurchasesStore,
} from 'Modules/RegistrationsAdmin/store/PurchasesStore';
import ModalRemoveRegistration from 'Modules/RegistrationsAdmin/modals/ModalRemoveRegistration';
import ModalDetailRegistration from 'Modules/RegistrationsAdmin/modals/ModalDetailRegistration';
import ModalEraseAllCustomerData from 'Modules/RegistrationsAdmin/modals/ModalEraseAllCustomerData';
import ModalDetailPurchases from 'Modules/RegistrationsAdmin/modals/ModalDetailPurchases';
import ModalQrCode from 'Modules/RegistrationsAdmin/modals/ModalQrCode';
import { useAuthUser, } from 'Tools/auth';
import CopyIcon from 'Utils/svg/CopyIcon';
import ModalExportRegistrations from 'Modules/RegistrationsAdmin/modals/ModalExportCustomers';
import ModalExportPurchases from 'Modules/RegistrationsAdmin/modals/ModalExportPurchases';
import { Claim, } from 'Apollo/graphql';
import Purchases from './PurchaseTable/Purchases';
import Registrations from './RegistrationTable/Registrations';

const selectStoreData = (s: RegistrationStore) => ({
  qrRegistrations: s.openModalQrRegistration,
  openRegistrationExportModal: s.openModalExportRegistration,
  qrRegistrationsBrandAmbassador: s.openModalQrRegistrationBrandAmbassador,
});
const selectOpenPurchaseExportModal = (s: PurchasesStore) =>
  s.openModalExportPurchase;

const RegistrationAdminPage = (): JSX.Element => {
  const { t, } = useTranslation('registrationsAdmin');
  const authUser = useAuthUser();
  const [value, setValue,] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const openPurchasesExportModal = usePurchasesStore(selectOpenPurchaseExportModal);
  const { qrRegistrations,openRegistrationExportModal, qrRegistrationsBrandAmbassador, } = useRegistrationStore(selectStoreData, shallow);
  const handleExport = value === 0 ? openRegistrationExportModal : openPurchasesExportModal;
  const showQrCodeModal =
    authUser?.claimsSet.has(Claim.ReadLoginHash) ||
    (authUser?.claimsSet.has(Claim.ReadBrandAmbassadorLoginHash) &&
      authUser.featureFlagsObject
        .FEATURE_FLAG_ENABLE_BRAND_AMBASSADOR_QR_REGISTRATION);

  return (
    <>
      <AppContainer>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          paddingBottom={2}
        >
          <Grid item>
            <Typography variant="h1">{t('registrations.title')}</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              {authUser?.claimsSet.has(Claim.AdminExportData) && (
                <Grid item>
                  <Button variant="outlined" color="inherit" size="large" onClick={handleExport}>
                    {t('registrations.btnExport')}
                  </Button>
                </Grid>
              )}
              {showQrCodeModal && (
                <Grid item>
                  <Button
                    startIcon={<CopyIcon />}
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={qrRegistrations}
                  >
                    {t('registrations.btnQrModal')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={t('registrations.title')} />
          <Tab label={t('purchases.title')} />
        </Tabs>
        {value === 0 ? <Registrations /> : <Purchases />}
      </AppContainer>
      <ModalRemoveRegistration />
      <ModalExportRegistrations />
      <ModalEraseAllCustomerData />
      <ModalDetailRegistration />
      <ModalDetailPurchases />
      <ModalExportPurchases />
      <ModalQrCode />
    </>
  );
};

export default RegistrationAdminPage;
