import { Claim, } from 'Apollo/graphql';
import { ROUTES, } from 'Utils/constants';
import AdministrationColoredIcon from 'Utils/svg/AdministrationColoredIcon';
import AssortmentColoredIcon from 'Utils/svg/AssortmentColoredIcon';
import ChristmasPrizeWheel from 'Utils/svg/ChristmasPrizeWheelIcon';
import GiftCodeIcon from 'Utils/svg/GiftCodesIcon';
import MyPointsColoredIcon from 'Utils/svg/MyPointsColoredIcon';
import MyProfileColoredIcon from 'Utils/svg/MyProfileColoredIcon';
import NewsColoredIcon from 'Utils/svg/NewsColoredIcon';
import PromotionColoredIcon from 'Utils/svg/PromotionColoredIcon';
import RegistrationsColoredIcon from 'Utils/svg/RegistrationsColoredIcon';
import ReplacementsColoredIcon from 'Utils/svg/ReplacementsColoredIcon';
import ShortRegistrationsColoredIcon from 'Utils/svg/ShortRegistrationsColoredIcon';
import SupportColoredIcon from 'Utils/svg/SupportColoredIcon';
import TasksColoredIcon from 'Utils/svg/TasksColoredIcon';
import { MenuItem, } from '../types';

export const SELLER_MENU_ITEMS: MenuItem[] = [
  {
    id: 'xmasWheel2023',
    exact: false,
    icon: <ChristmasPrizeWheel />,
    route: `/${ROUTES.prizeWheelXmas2023}`,
    textKey: 'common:sellerSidebar.xmasWheel2023',
    claims: [Claim.GeneratePrizeWheelXmas_2023,],
    flag: null,
    sx: {
      backgroundImage: 'url(images/xmas-wheel/prize_wheel_menu_item_bg.svg)',
      backgroundSize: 'cover',
      color: '#FFFFFF',
    },
  },
  {
    id: 'registrations',
    exact: false,
    icon: <RegistrationsColoredIcon />,
    route: `/${ROUTES.registration}`,
    textKey: 'common:sellerSidebar.registrations',
    claims: [Claim.SellerReplacementsView,],
    flag: null,
  },
  {
    id: 'shortRegistrations',
    exact: false,
    icon: <ShortRegistrationsColoredIcon />,
    route: `/${ROUTES.shortRegistration}`,
    textKey: 'common:sellerSidebar.shortRegistrations',
    claims: [Claim.SellerShortRegistrationsView,],
    flag: null,
  },
  {
    id: 'replacements',
    exact: false,
    icon: <ReplacementsColoredIcon />,
    route: `/${ROUTES.replacement}`,
    textKey: 'common:sellerSidebar.replacements',
    claims: [Claim.SellerReplacementsView,],
    flag: 'FEATURE_FLAG_ENABLE_REPLACEMENTS',
  },
  {
    id: 'assortment',
    exact: false,
    icon: <AssortmentColoredIcon />,
    route: `/${ROUTES.assortment}`,
    textKey: 'common:sellerSidebar.assortment',
    claims: [Claim.SellerAssortmentView,],
    flag: null,
  },
  {
    id: 'promotions',
    exact: false,
    icon: <PromotionColoredIcon />,
    route: `/${ROUTES.promotions}`,
    textKey: 'common:sellerSidebar.promotions',
    claims: [Claim.SellerPromotionsView,],
    flag: 'FEATURE_FLAG_ENABLE_PROMOTIONS',
  },

  {
    id: 'giftCodes',
    exact: false,
    icon: <GiftCodeIcon />,
    route: `/${ROUTES.giftCodeSeller}`,
    textKey: 'common:sellerSidebar.giftCodes',
    flag: 'FEATURE_FLAG_ENABLE_SELF_REGISTRATION',
  },

  {
    id: 'czGiftCodes',
    exact: false,
    icon: <GiftCodeIcon />,
    route: `/${ROUTES.giftCodeSeller}`,
    textKey: 'common:sellerSidebar.giftCodes',
    claims: [Claim.ReadGiftCode,],
    flag: null,
  },

  // {
  //   id: 'freeTrial',
  //   exact: false,
  //   icon: <FreeTrialColoredIcon />,
  //   route: `/todo`,
  //   textKey: 'common:sellerSidebar.freeTrial',
  //   permission: null,
  // },
  {
    id: 'myPoints',
    exact: false,
    icon: <MyPointsColoredIcon />,
    route: `/${ROUTES.myPoints}`,
    textKey: 'common:sellerSidebar.myPoints',
    claims: [Claim.SellerMyPointsView,],
    flag: 'FEATURE_FLAG_ENABLE_POINTS',
  },
  {
    id: 'sellerTasks',
    exact: false,
    icon: <TasksColoredIcon />,
    route: `/${ROUTES.tasks}`,
    textKey: 'common:sellerSidebar.tasks',
    claims: [Claim.SellerTasksView,],
    flag: null,
  },
  {
    id: 'news',
    exact: false,
    icon: <NewsColoredIcon />,
    route: `/${ROUTES.news}`,
    textKey: 'common:sellerSidebar.news',
    claims: [Claim.SellerNewsView,],
    flag: null,
  },
  {
    id: 'support',
    exact: false,
    icon: <SupportColoredIcon />,
    route: `/${ROUTES.sellerSupport}`,
    textKey: 'common:sellerSidebar.support',
    claims: [Claim.SellerSupportView,],
    flag: null,
  },
  {
    id: 'myProfile',
    exact: false,
    icon: <MyProfileColoredIcon />,
    route: `/${ROUTES.myProfile}`,
    textKey: 'common:sellerSidebar.myProfile',
    claims: [Claim.SellerMyProfileView,],
    flag: null,
  },
  {
    id: 'administration',
    exact: false,
    icon: <AdministrationColoredIcon />,
    route: `/${ROUTES.admin}`,
    textKey: 'common:sidebar.administration',
    claims: [Claim.AdminApp,],
    flag: null,
  },
];
