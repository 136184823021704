import Grid from '@mui/material/Grid';
import { ChainFragment, Claim, PointOfSellOptionFragment, UserFragment, useChainsQuery, useGetAllUserRolesQuery, } from 'Apollo/graphql';
import { FormInputAutocomplete, FormInputPhoneNumber, FormInputText, } from 'Components/Form';
import NotEditableTextClassic from 'Components/Inputs/NotEditableTextClassic';
import { useAuthUser, } from 'Tools/auth';
import { areOptionsEqualById, getOptionFieldId, renderOptionById, } from 'Utils/helpers';
import { UserRolesManageOption, useOptionsUserRolesManage, } from 'Utils/options/useOptionsUserRolesManage';
import { useSearchOptionsPos, } from 'Utils/options/useSearchOptionsPos';
import { valueOrEmpty, } from 'Utils/parsers';
import { useEffect, useMemo, useState, } from 'react';
import { useFormContext, useWatch, } from 'react-hook-form';
import { useTranslation, } from 'react-i18next';

interface Props {
  user: UserFragment;
}

const EditUserForm = ({ user, }: Props): JSX.Element => {
  const { t, } = useTranslation('users');
  const authUser = useAuthUser();
  const { data, } = useGetAllUserRolesQuery();
  const roleInfo = useMemo(() => data?.getAllRolesInfo.find((role) => role.roleCode === user.roleCode), [data?.getAllRolesInfo,]);
  const canTransformRole = authUser?.claimsSet.has(Claim.ManageUserRole) && roleInfo?.canBeTransformed;
  const { control, setValue, } = useFormContext();
  const role = useWatch({ name: 'role', control, }) as UserRolesManageOption | null;
  const chainId = useWatch({ name: 'chainId', control, }) as ChainFragment | null;

  const userRolesOptions = useOptionsUserRolesManage();
  const chainsResult = useChainsQuery({ fetchPolicy: 'cache-and-network', });
  const posOptions = useSearchOptionsPos(chainId?.id);
   const [posLoads,addPosLoad,] = useState(0);
   const [chainLoads,addChainLoad,] = useState(0);
  useEffect(() => {
    addPosLoad((current)=>(current + 1));
  }, [posOptions.loading,]);
  useEffect(() => {
    addChainLoad((current)=>(current + 1));
  }, [chainsResult.called,]);

  useEffect(() => {
    setValue('posId', null);
    setValue('posIds', []);
  }, [chainId, setValue,]);

  useEffect(() => {
    if (!role?.requiresChainId) setValue('chainId', null);
    if (!role?.requiresPosId) setValue('posId', null);
    if (!role?.requiresPosIds) setValue('posIds', []);
  }, [role, setValue,]);

  useEffect(() => {
    const currentRole = userRolesOptions.options.find((option) => option.id === user.roleCode);
    setValue('role', currentRole);
  }, [userRolesOptions.options,]);

  useEffect(() => {
    if (chainLoads === 1) {
      const currentChain = chainsResult.data?.chains.find((option) => option.id === user.chainId);
      setValue('chainId', currentChain || null);
    }
  }, [chainsResult.data,chainLoads,]);

  useEffect(() => {
    if (posLoads === 1) {
      const currentPos = posOptions.options.find((option) => option.id === user.posId);
      setValue('posId', currentPos || null);
    }
  }, [posOptions.options,posLoads,]);

  useEffect(() => {
    if (posLoads === 1) {
      const posIds = [];
      for (let i = 0; i < (user.posIds || []).length; i++) {
        const possiblePosId = posOptions.options.find((option) => option.id === (user.posIds || [])[i]);
        if (possiblePosId) posIds.push(possiblePosId);
      }
      setValue('posIds', posIds || null);
    }
  }, [posOptions.options,]);

  return (
    <Grid container spacing={3}>
      {canTransformRole ? (
        <>
          <Grid item xs={12}>
            <FormInputAutocomplete<UserRolesManageOption>
              name="role"
              triggerFields={['chainId', 'posId', 'posIds',]}
              getOptionLabel={userRolesOptions.getOptionLabel}
              isOptionEqualToValue={userRolesOptions.isOptionEqualToValue}
              options={userRolesOptions.options}
              renderOption={renderOptionById(userRolesOptions.getOptionLabel)}
              label={t('user.labelRole')}
              placeholder={t('common:select.placeholder')}
              loading={userRolesOptions.loading}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputAutocomplete<ChainFragment>
              name="chainId"
              getOptionLabel={getOptionFieldId}
              isOptionEqualToValue={areOptionsEqualById}
              renderOption={renderOptionById(getOptionFieldId)}
              options={chainsResult.data?.chains || []}
              loading={chainsResult.loading}
              label={t('user.labelChainId')}
              placeholder={t('common:select.placeholder')}
              required={role?.requiresChainId}
              disabled={!role?.requiresChainId}
            />
          </Grid>
          {role?.requiresPosId && (
            <Grid item xs={12}>
              <FormInputAutocomplete<PointOfSellOptionFragment>
                name="posId"
                options={posOptions.options}
                getOptionLabel={posOptions.getOptionLabel}
                isOptionEqualToValue={posOptions.isOptionEqualToValue}
                renderOption={renderOptionById(posOptions.getOptionLabel)}
                filterOptions={posOptions.filterOptions}
                loading={posOptions.loading}
                onInputChange={posOptions.onInputChange}
                noOptionsText={posOptions.noOptionsText}
                label={t('user.labelPosId')}
                placeholder={t('common:select.placeholder')}
                required={role?.requiresPosId}
                disabled={!chainId || !role?.requiresPosId}
              />
            </Grid>
          )}
          {authUser?.featureFlagsObject.FEATURE_FLAG_SHOW_POS_IDS_IN_USER_DASHBOARD && role?.requiresPosIds && (
            <Grid item xs={12}>
              <FormInputAutocomplete<PointOfSellOptionFragment, true>
                name="posIds"
                multiple
                options={posOptions.options}
                getOptionLabel={posOptions.getOptionLabel}
                isOptionEqualToValue={posOptions.isOptionEqualToValue}
                renderOption={renderOptionById(posOptions.getOptionLabel)}
                filterOptions={posOptions.filterOptions}
                loading={posOptions.loading}
                onInputChange={posOptions.onInputChange}
                noOptionsText={posOptions.noOptionsText}
                label={t('user.labelPosIds')}
                placeholder={t('common:select.placeholder')}
                required={role?.requiresPosIds}
                disabled={!chainId || !role?.requiresPosIds}
              />
            </Grid>
          )}
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <NotEditableTextClassic label={t('user.labelRole')}>
              {t(`common:options.userRoles.${user.roleCode}`, user.roleCode || '-')}
            </NotEditableTextClassic>
          </Grid>
          <Grid item xs={12}>
            <NotEditableTextClassic label={t('user.labelChainId')}>{user.chainId || '-'}</NotEditableTextClassic>
          </Grid>
          <Grid item xs={12}>
            <NotEditableTextClassic label={t('user.labelPosId')}>{user.posId || '-'}</NotEditableTextClassic>
          </Grid>
          {authUser?.featureFlagsObject.FEATURE_FLAG_SHOW_POS_IDS_IN_USER_DASHBOARD && (
            <Grid item xs={12}>
              <NotEditableTextClassic label={t('user.labelPosIds')}>{valueOrEmpty((user.posIds || []).join(', '))}</NotEditableTextClassic>
            </Grid>
          )}
        </>
      )}
      <Grid item xs={12}>
        <NotEditableTextClassic label={t('user.labelUsername')}>{user.username || '-'}</NotEditableTextClassic>
      </Grid>
      <Grid item xs={12}>
        <FormInputText name="firstName" label={t('user.labelFirstName')} required autoComplete="new-firstName" id="new-firstName" />
      </Grid>
      <Grid item xs={12}>
        <FormInputText name="lastName" label={t('user.labelLastName')} required autoComplete="new-lastName" id="new-lastName" />
      </Grid>
      <Grid item xs={12}>
        <FormInputText name="email" label={t('user.labelEmail')} required autoComplete="new-email" id="new-email" />
      </Grid>
      <Grid item xs={12}>
        <FormInputPhoneNumber namePrefix="phonePrefix" namePhone="phoneNumber" label={t('user.labelPhone')} required id="nope" />
      </Grid>
    </Grid>
  );
};

export default EditUserForm;
