import { Routes, Route, } from 'react-router-dom';
import { ROUTES, } from 'Utils/constants';
import { useAuthInitUser, useAuthUser, } from 'Tools/auth';
import ProtectedRoute from 'Components/ProtectedRoute';
import LayoutSeller from 'Components/Layouts/LayoutSeller';
import LayoutAdmin from 'Components/Layouts/LayoutAdmin';
import LoginPage from 'Modules/Auth/pages/LoginPage';
import DashboardAdminPage from 'Modules/DashboardAdmin/pages/DashboardAdminPage';
import DashboardSellerPage from 'Modules/DashboardSeller/pages/DashboardSellerPage';
import AppLayoutNoUser from 'Components/AppLayout/AppLayoutNoUser';
import AssortmentSellerPage from 'Modules/AssortmentSeller/pages/AssortmentSellerPage';
import AssortmentAdminPage from 'Modules/AssortmentAdmin/pages/AssortmentAdminPage';
import PointsTransactionAdminPage from 'Modules/PointsTransactionAdmin/pages/PointsTransactionAdminPage';
import RegistrationSellerPage from 'Modules/RegistrationSeller/pages/RegistrationSellerPage';
import PromotionsAdminPage from 'Modules/PromotionsAdmin/pages/PromotionsAdminPage';
import UsersPage from 'Modules/Users/pages/UsersPage';
import PointOfSellPage from 'Modules/PointOfSell/pages/PointOfSellPage';
import ReplacementSellerPage from 'Modules/ReplacementSeller/pages/ReplacementSellerPage';
import ReplacementAdminPage from 'Modules/ReplacementAdmin/pages/ReplacementAdminPage';
import RegistrationAdminPage from 'Modules/RegistrationsAdmin/pages/RegistrationAdminPage';
import ForgottenPasswordPage from 'Modules/Auth/pages/ForgottenPasswordPage';
import ResetPasswordPage from 'Modules/Auth/pages/ResetPasswordPage';
import LoginQrCodePage from 'Modules/Auth/pages/LoginQrCodePage';
import PromotionsSellerPage from 'Modules/PromotionsSeller';
import NewsArticlesAdminPage from 'Modules/NewsArticlesAdmin/pages/NewsArticles';
import SupportSeller from 'Modules/SupportSeller/pages';
import NewsArticlesListPage from 'Modules/NewsArticlesSeller/pages/NewsArticlesListPage';
import NewsArticleDetailPage from 'Modules/NewsArticlesSeller/pages/NewsArticleDetailPage';
import TasksSellerPage from 'Modules/TasksSeller/pages/TasksSellerPage';
import TaskDetailPage from 'Modules/TasksAdmin/pages/TaskDetailPage';
import TasksAdminPage from 'Modules/TasksAdmin/pages/TasksTable';
import FillTaskPage from 'Modules/TasksSeller/pages/FillTaskPage';
import CreateTaskPage from 'Modules/TasksAdmin/pages/CreateTaskPage';
import TransactionList from 'Modules/MyPointsSeller/pages/TransactionList';
import MyPointsSeller from 'Modules/MyPointsSeller/pages/MyPoints';
import TaskReviewPage from 'Modules/TasksAdmin/pages/TaskReviewPage';
import MyProfile from 'Modules/MyProfile/pages/MyProfile';
import EditMyProfile from 'Modules/MyProfile/pages/EditMyProfile';
import VouchersAdminPage from 'Modules/Vouchers/pages/RegistrationAdminPage';
import VoucherDetail from 'Modules/MyPointsSeller/pages/VoucherDetail';
import DeleteMyProfile from 'Modules/MyProfile/pages/DeleteMyProfile';
import { useTranslation } from 'react-i18next';
import EditMyTaxInformation from 'Modules/MyProfile/pages/EditMyTaxInformation';
import GiftCodePage from 'Modules/GiftCodeSeller/pages/CzGiftCodePage';
import ChristmasPrizeWheelPage from 'Modules/PrizeWheel/pages/ChristmasWheel';
import LayoutDashboard from 'Components/Layouts/LayoutDashboard';
import Users from 'Modules/Dashboards/pages/Users';
import Pos from 'Modules/Dashboards/pages/Pos';
import TasksAndPoints from 'Modules/Dashboards/pages/TasksAndPoints';
import Registrations from 'Modules/Dashboards/pages/Registrations';
import Replacements from 'Modules/Dashboards/pages/Replacements';
import Chains from 'Modules/Dashboards/pages/Chains';
import UsersByRolesPage from 'Modules/Dashboards/pages/Users/UsersByRoles/UsersByRoles';
import TopPosInRegistrationsPage from 'Modules/Dashboards/pages/Pos/TopPosInRegistrations';
import ActiveInactivePosPage from 'Modules/Dashboards/pages/Pos/ActiveInactivePos';
import TopPosInReplacementsPage from 'Modules/Dashboards/pages/Pos/TopPosInReplacements';
import TotalReplacementsPage from 'Modules/Dashboards/pages/Replacements/TotalReplacements';
import TopReasonsForReplacementsPage from 'Modules/Dashboards/pages/Replacements/TopReasonsForReplacements';
import PointsCategoriesPage from 'Modules/Dashboards/pages/TasksAndPoints/PointsCategories';
import TotalPointsPage from 'Modules/Dashboards/pages/TasksAndPoints/TotalPoints';
import ActiveInactiveChainsPage from 'Modules/Dashboards/pages/Chains/ActiveInactiveChains';
import TopChainsInRegistrationsPage from 'Modules/Dashboards/pages/Chains/TopChainsInRegistrations';
import TopChainsInReplacementsPage from 'Modules/Dashboards/pages/Chains/TopChainsInReplacements';
import ActiveInactiveUsersPage from 'Modules/Dashboards/pages/Users/ActiveInactiveUsers';
import TotalRegistrationsPage from 'Modules/Dashboards/pages/Registrations/TotalRegistrations';
import MethodOfVerificationPage from 'Modules/Dashboards/pages/Registrations/MethodOfVerification';
import ReDOIPage from 'Modules/Dashboards/pages/Registrations/ReDOI';
import RepurchasesPage from 'Modules/Dashboards/pages/Registrations/Repurchases';
import GenderTitlePage from 'Modules/Dashboards/pages/Registrations/GenderTitle';
import SplitOfTotalRegistrationsPage from 'Modules/Dashboards/pages/Registrations/SplitOfTotalRegistrations';
import PreviouslyOwnedDevicePage from 'Modules/Dashboards/pages/Registrations/PreviouslyOwnedDevice';
import PurchasedProductsPage from 'Modules/Dashboards/pages/Registrations/PurchasedProducts';
import RegistrationsByCitiesPage from 'Modules/Dashboards/pages/Registrations/RegistrationsByCities';
import NumberOfTaskCompletionsPage from 'Modules/Dashboards/pages/TasksAndPoints/NumberOfCompletedTasks';
import NumberOfActiveTasksPage from 'Modules/Dashboards/pages/TasksAndPoints/NumberOfActiveTasks';
import TotalNumberOfAvailableGiftCodesPage from 'Modules/Dashboards/pages/ImportedPlGiftCodes/TotalNumberOfAvailableGiftCodes';
import TotalNumberOfSentGiftCodesPage from 'Modules/Dashboards/pages/ImportedPlGiftCodes/TotalNumberOfSentGiftCodes';
import ImportedPlGiftCodes from 'Modules/Dashboards/pages/ImportedPlGiftCodes';
import PersonalizedDashboard from 'Modules/Dashboards/pages/PersonalizedDashboard';
import SplitOfTotalRegistrationsByRegistrationAgePage from 'Modules/Dashboards/pages/Registrations/SplitOfTotalRegistrationsByRegistrationAge';
import { Claim, } from 'Apollo/graphql';
import LoadingScreen from './LoadingScreen';
import AcceptTermsAndConditionsModal from './AcceptTermsAndConditions/AcceptTermsAndConditionsModal';
import NotificationPopUpModal from './NotificationPopUp/NotificationPopUpModal';

const Core = (): JSX.Element => {
  const isLoading = useAuthInitUser();
  const authUser = useAuthUser();
  const {
    i18n: { language, },
  } = useTranslation('common');
  if (isLoading) return <LoadingScreen />;

  return (
    <>
      <NotificationPopUpModal />
      <AcceptTermsAndConditionsModal />
      <Routes>
        <Route element={<AppLayoutNoUser />}>
          <Route path={ROUTES.login}>
            <Route index element={<LoginPage />} />
            <Route path=":code" element={<LoginQrCodePage />} />
          </Route>
          <Route
            path={ROUTES.forgottenPassword}
            element={<ForgottenPasswordPage />}
          />
          <Route
            path={`${ROUTES.resetPassword}/:resetCode`}
            element={<ResetPasswordPage />}
          />
        </Route>

        <Route
          element={
            <ProtectedRoute claimsRequired={[Claim.SellerApp,]}>
              <LayoutSeller />
            </ProtectedRoute>
          }
        >
          <Route index element={<DashboardSellerPage />} />
          <Route path="*" element={<DashboardSellerPage />} />
          <Route
            path={ROUTES.assortment}
            element={
              <ProtectedRoute claimsRequired={[Claim.SellerAssortmentView,]} >
                <AssortmentSellerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.promotions}
            element={
              <ProtectedRoute
                claimsRequired={[Claim.SellerPromotionsView,]}
                isAllowed={authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_PROMOTIONS}
              >
                <PromotionsSellerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.prizeWheelXmas2023}
            element={
              <ProtectedRoute claimsRequired={[Claim.GeneratePrizeWheelXmas_2023,]}>
                <ChristmasPrizeWheelPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.registration}
            element={
              <ProtectedRoute claimsRequired={[Claim.SellerRegistrationsView,]}>
                <RegistrationSellerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.shortRegistration}
            element={
              <ProtectedRoute claimsRequired={[Claim.SellerShortRegistrationsView,]}>
                <RegistrationSellerPage isShortRegistration />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.myPoints}
            element={
              <ProtectedRoute
                claimsRequired={[Claim.SellerMyPointsView,]}
                isAllowed={authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_POINTS}
              />
            }
          >
            <Route index element={<MyPointsSeller />} />
            <Route path="*" element={<MyPointsSeller />} />
            <Route
              path={ROUTES.transactions}
              element={
                <ProtectedRoute
                  claimsRequired={[Claim.SellerMyPointsView,]}
                  isAllowed={authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_POINTS}
                >
                  <TransactionList />
                </ProtectedRoute>
              }
            />
            <Route path=":voucherId" element={<VoucherDetail />} />
          </Route>
          <Route
            path={ROUTES.sellerSupport}
            element={
              <ProtectedRoute claimsRequired={[Claim.SellerSupportView,]}>
                <SupportSeller />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.replacement}
            element={
              <ProtectedRoute
                claimsRequired={[Claim.SellerReplacementsView,]}
                isAllowed={authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_REPLACEMENTS}
              >
                <ReplacementSellerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.giftCodeSeller}
            element={
              <ProtectedRoute
                claimsRequired={[Claim.ReadGiftCode,]}
                isAllowed={authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_SELF_REGISTRATION}
              >
                <GiftCodePage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.tasks}
            element={
                <ProtectedRoute claimsRequired={[Claim.SellerTasksView,]} />
            }
          >
            <Route index element={<TasksSellerPage />} />
            <Route path="*" element={<TasksSellerPage />} />
            <Route path=":taskId" element={<FillTaskPage />} />
          </Route>
          <Route
            path={ROUTES.news}
            element={
              <ProtectedRoute claimsRequired={[Claim.SellerNewsView,]} />
            }>
            <Route index element={<NewsArticlesListPage />} />
            <Route path="*" element={<NewsArticlesListPage />} />
            <Route path=":id" element={<NewsArticleDetailPage />} />
          </Route>
          <Route
            path={ROUTES.myProfile}
            element={
              <ProtectedRoute claimsRequired={[Claim.SellerMyProfileView,]} />
            }>
            <Route index element={<MyProfile />} />
            <Route path="*" element={<MyProfile />} />
            <Route
              path={ROUTES.editMyTaxInfo}
              element={
                <ProtectedRoute
                  claimsRequired={[Claim.SellerMyTaxInfoView,]}
                  isAllowed={authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_TAX_INFORMATION}
                >
                  <EditMyTaxInformation />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.editMyProfile}
              element={
                <ProtectedRoute claimsRequired={[Claim.ManageMyProfile,]}>
                  <EditMyProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.deleteMyProfile}
              element={
                <ProtectedRoute claimsRequired={[Claim.ManageMyProfile,]}>
                  <DeleteMyProfile />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>

        <Route path={ROUTES.admin}>
          <Route
            element={
              <ProtectedRoute claimsRequired={[Claim.AdminApp,]}>
                <LayoutAdmin />
              </ProtectedRoute>
            }
          >
            <Route index element={<DashboardAdminPage />} />
            <Route
              path={ROUTES.assortment}
              element={
                <ProtectedRoute claimsRequired={[Claim.AdminAssortmentView,]}>
                  <AssortmentAdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.promotions}
              element={
                <ProtectedRoute
                  claimsRequired={[Claim.AdminPromotionsView,]}
                  isAllowed={authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_PROMOTIONS}
                >
                  <PromotionsAdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.users}
              element={
                <ProtectedRoute claimsRequired={[Claim.AdminUsersView,]}>
                  <UsersPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.pointsOfSell}
              element={
                <ProtectedRoute claimsRequired={[Claim.AdminPosView,]}>
                  <PointOfSellPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.pointsTransaction}
              element={
                <ProtectedRoute
                  claimsRequired={[Claim.AdminPointsView,]}
                  isAllowed={authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_POINTS}
                >
                  <PointsTransactionAdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.replacement}
              element={
                <ProtectedRoute claimsRequired={[Claim.AdminReplacementsView,]}>
                  <ReplacementAdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.tasks}
              element={
                <ProtectedRoute claimsRequired={[Claim.AdminTasksView,]} />
              }>
              <Route index element={<TasksAdminPage />} />
              <Route path="*" element={<TasksAdminPage />} />
              <Route
                path={ROUTES.create}
                element={
                  <ProtectedRoute claimsRequired={[Claim.ManageTask,]}>
                    <CreateTaskPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path={`${ROUTES.review}/:taskId`}
                element={
                  <ProtectedRoute claimsRequired={[Claim.ReviewTask,]}>
                    <TaskReviewPage />
                  </ProtectedRoute>
                }
              />
              <Route path=":taskId" element={<TaskDetailPage />} />
            </Route>
            <Route
              path={ROUTES.news}
              element={
                <ProtectedRoute claimsRequired={[Claim.AdminNewsView,]}>
                  <NewsArticlesAdminPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.registration}
              element={
                <ProtectedRoute claimsRequired={[Claim.AdminRegistrationsView,]} >
                  <RegistrationAdminPage />
                </ProtectedRoute>
              }
            />
            {language !== 'hu' && (
              <Route
                path={ROUTES.vouchers}
                element={
                  <ProtectedRoute claimsRequired={[Claim.AdminVouchersView,]}>
                    <VouchersAdminPage />
                  </ProtectedRoute>
                }
              />
            )}
            <Route path="*" element={<DashboardAdminPage />} />
          </Route>
        </Route>

        <Route path={ROUTES.dashboard}>
          <Route
            element={
              <ProtectedRoute claimsRequired={[Claim.DashboardsApp,]}>
                <LayoutDashboard />
              </ProtectedRoute>
            }
          >
            <Route index element={<PersonalizedDashboard />} />
            <Route
              path={ROUTES.dashboardUsers}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[Claim.DashboardUsersActiveUsers, Claim.DashboardUsersTotalNumberOfUsers, Claim.DashboardUsersUserRoles,]}
                >
                  <Users />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.dashboardPos}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[Claim.DashboardPointsOfSaleActivePos, Claim.DashboardPointsOfSaleTop_10Registrations, Claim.DashboardPointsOfSaleTop_10Replacements, Claim.DashboardPointsOfSaleTotalNumberOfPos,]}
                >
                  <Pos />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.dashboardRegistrations}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardRegistrationsConsumerAgeGroups,
                    Claim.DashboardRegistrationsConsumerTitleGender,
                    Claim.DashboardRegistrationsMethodOfVerification,
                    Claim.DashboardRegistrationsPreviouslyOwnedDevice,
                    Claim.DashboardRegistrationsPurchasedProduct,
                    Claim.DashboardRegistrationsReDoi,
                    Claim.DashboardRegistrationsTop_10Cities,
                    Claim.DashboardRegistrationsTotalNumberOfRePurchases,
                    Claim.DashboardRegistrationsTotalNumberOfRegistrations,
                  ]}
                >
                  <Registrations />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.dashboardReplacements}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardReplacementsTotalNumberOfReplacements,
                    Claim.DashboardReplacementsTop_10ReasonsForReplacement,
                  ]}
                >
                  <Replacements />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.dashboardGiftCodes}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardGiftCodesTotalNumberOfAvailableGiftCodes,
                    Claim.DashboardGiftCodesTotalNumberOfSendGiftCodesByChain,
                  ]}
                  isAllowed={authUser?.featureFlagsObject.FEATURE_FLAG_ENABLE_GIFT_CODES_DASHBOARD}
                >
                  <ImportedPlGiftCodes />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.dashboardTasksAndPoints}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardPointsAndTasksPointsCategory,
                    Claim.DashboardPointsAndTasksNumberOfActiveTasks,
                    Claim.DashboardPointsAndTasksTotalNumberOfPoints,
                    Claim.DashboardPointsAndTasksNumberOfTasksCompletions,
                  ]}>
                  <TasksAndPoints />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.dashboardChains}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardChainsActiveChains,
                    Claim.DashboardChainsTop_10Replacements,
                    Claim.DashboardChainsTop_10Registrations,
                    Claim.DashboardChainsTotalNumberOfChains,
                  ]}
                >
                  <Chains />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardUsersByRole}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardUsersUserRoles,
                  ]}
                >
                  <UsersByRolesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardActiveInactiveUsers}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardUsersUserRoles,
                  ]}
                >
                  <ActiveInactiveUsersPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardTopPosInRegistrations}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardPointsOfSaleTop_10Registrations,
                  ]}
                >
                  <TopPosInRegistrationsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardActiveInactivePos}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardPointsOfSaleActivePos,
                  ]}
                >
                  <ActiveInactivePosPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardTopPosInReplacements}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardPointsOfSaleTop_10Replacements,
                  ]}
                >
                  <TopPosInReplacementsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardTotalReplacements}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardReplacementsTotalNumberOfReplacements,
                  ]}
                >
                  <TotalReplacementsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardTopReasonsForReplacements}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardReplacementsTop_10ReasonsForReplacement,
                  ]}
                >
                  <TopReasonsForReplacementsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardPointsCategories}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardPointsAndTasksPointsCategory,
                  ]}
                >
                  <PointsCategoriesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardTotalPoints}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardPointsAndTasksTotalNumberOfPoints,
                  ]}
                >
                  <TotalPointsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardActiveInactiveChains}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardChainsActiveChains,
                  ]}
                >
                  <ActiveInactiveChainsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardTopChainInRegistrations}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardChainsTop_10Registrations,
                  ]}
                >
                  <TopChainsInRegistrationsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardTopChainInReplacements}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardChainsTop_10Replacements,
                  ]}
                >
                  <TopChainsInReplacementsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardTotalRegistrations}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardRegistrationsTotalNumberOfRegistrations,
                  ]}
                >
                  <TotalRegistrationsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardMethodOfVerification}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardRegistrationsMethodOfVerification,
                  ]}
                >
                  <MethodOfVerificationPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardReDOI}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardRegistrationsReDoi,
                  ]}
                >
                  <ReDOIPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardRepurchases}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardRegistrationsTotalNumberOfRePurchases,
                  ]}
                >
                  <RepurchasesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardGenderTitle}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardRegistrationsConsumerTitleGender,
                  ]}
                >
                  <GenderTitlePage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardSplitOfTotalRegistrations}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardRegistrationsConsumerAgeGroups,
                  ]}
                >
                  <SplitOfTotalRegistrationsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardSplitOfTotalRegistrationsByRegistrationAge}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardRegistrationsConsumerAgeGroups,
                  ]}
                >
                  <SplitOfTotalRegistrationsByRegistrationAgePage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardPreviouslyOwnedDevice}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardRegistrationsPreviouslyOwnedDevice,
                  ]}
                >
                  <PreviouslyOwnedDevicePage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardPurchasedProducts}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardRegistrationsPurchasedProduct,
                  ]}
                >
                  <PurchasedProductsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardRegistrationsByCities}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardRegistrationsTop_10Cities,
                  ]}
                >
                  <RegistrationsByCitiesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardNumberOfActiveTasks}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardPointsAndTasksNumberOfActiveTasks,
                  ]}
                >
                  <NumberOfActiveTasksPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardNumberOfTaskCompletions}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardPointsAndTasksNumberOfTasksCompletions,
                  ]}
                >
                  <NumberOfTaskCompletionsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardTotalNumberOfAvailableGiftCodes}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardGiftCodesTotalNumberOfAvailableGiftCodes,
                  ]}
                >
                  <TotalNumberOfAvailableGiftCodesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={`${ROUTES.dashboardTotalNumberOfSentGiftCodes}`}
              element={
                <ProtectedRoute
                  claimCheckStrategy='any'
                  claimsRequired={[
                    Claim.DashboardGiftCodesTotalNumberOfSendGiftCodesByChain,
                  ]}
                >
                  <TotalNumberOfSentGiftCodesPage />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default Core;
