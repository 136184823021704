import { AuthUserFragment, Claim, FeatureFlagObjectFragment, } from 'Apollo/graphql';

export const hasAuthUserPermission = (
  authUser?: AuthUserFragment | null,
  claim?: null | Claim[],
  flag?: keyof FeatureFlagObjectFragment | null,
): boolean => {
  const hasFeatureFlag = !flag || Boolean(authUser?.featureFlagsObject[flag]);
  if (!hasFeatureFlag) {
    return false;
  }

  if (authUser?.roleCode === 'SUPER_ADMIN' || !claim) {
    return true;
  }

  const claims = !Array.isArray(claim) ? [claim,] : claim;
  const hasClaims = !claim || claims.some((perm) => Boolean(authUser?.claims.includes(perm)));
  return hasClaims;
};
