import { Claim, } from 'Apollo/graphql';
import { ReactNode, ReactElement, } from 'react';
import { Navigate, Outlet, } from 'react-router-dom';
import { useAuthUser, } from 'Tools/auth';
import { useAuthRedirectRoute, } from 'Tools/auth/hooks/useAuthRedirectRoute';

type ClaimCheckStrategy = 'all' | 'any';

interface Props {
  claimsRequired?: Claim[],
  redirectToPath?: string,
  children?: ReactNode,
  isAllowed?: boolean,
  claimCheckStrategy?: ClaimCheckStrategy,
};

const ProtectedRoute = ({
  claimsRequired = [],
  isAllowed = true,
  redirectToPath,
  claimCheckStrategy = 'all',
  children,
}: Props): JSX.Element => {
  const authUser = useAuthUser();
  const notAllowedRedirect = useAuthRedirectRoute(redirectToPath);

  const hasRequiredClaims = claimCheckStrategy === 'all'
    ? claimsRequired.every(claim => authUser?.claimsSet.has(claim))
    : claimsRequired.some(claim => authUser?.claimsSet.has(claim));

  if (!isAllowed || !hasRequiredClaims) {
    return (
      <Navigate
        to={notAllowedRedirect}
        replace
      />
    );
  };

  return children ? children as ReactElement : <Outlet />;
};

export default ProtectedRoute;
